"use client";

import { LoginForm } from "@/components/organisms/login-form";

export default function LoginPage() {
  return (
    <main className="container p-10 mx-auto gap-6 flex flex-col items-center justify-center mt-20">
      <div className="min-w-md max-w-lg h-fit w-full border p-6 rounded-lg shadow-lg mx-auto">
        <LoginForm />
      </div>
    </main>
  );
}
